<template>
  <div class="animated fadeIn">
    <!-- Detail User Army -->
    <b-card no-body>
      <b-card-header>
        Detail User Army
        <div class="card-header-actions">
          <a
            href="user-army"
            class="card-header-action"
            rel="noreferrer noopener"
          >
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <br />
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
          <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
          </div>
        </div>
        <b-row class="mb-4">
          <!-- User -->
          <b-col sm="2">
            <h6>User</h6>
            <div class="mb-4">
              <strong>{{
                this.detailUser.name && this.detailUser.email
                  ? `${this.detailUser.name} (${this.detailUser.email})`
                  : "-"
              }}</strong>
            </div>
          </b-col>
          <!-- Title -->
          <b-col sm="2">
            <h6>Title</h6>
            <div class="mb-4">
              <strong>{{ this.detailUser.title || "-" }}</strong>
            </div>
          </b-col>
          <!-- Current Point -->
          <b-col sm="2">
            <h6>{{ `Current Point ${this.currentlyMonth()}` }}</h6>
            <div class="mb-4">
              <strong>{{ this.detailUser.currentPoint || "-" }}</strong>
            </div>
          </b-col>
          <!-- Current Benefit -->
          <b-col sm="2">
            <h6>
              <h6>{{ `Current Benefit ${this.currentlyMonth()}` }}</h6>
            </h6>
            <div class="mb-4">
              <strong>{{ this.detailUser.currentBenefit || "-" }}</strong>
            </div>
          </b-col>
          <!-- Current Treasure -->
          <b-col sm="2">
            <h6>{{ `Current Treasure ${this.currentlyMonth()}` }}</h6>
            <div class="mb-4">
              <strong>{{ this.detailUser.currentTreasure || "-" }}</strong>
            </div>
          </b-col>
          <!-- Register Date -->
          <b-col sm="2">
            <h6>Register Army Date</h6>
            <div class="mb-4">
              <strong>{{ this.detailUser.registerDate || "-" }}</strong>
            </div>
          </b-col>
          <!-- Kode Referral -->
          <b-col sm="2">
            <h6>Phone Number</h6>
            <div class="mb-4">
              <strong>{{ this.detailUser.phoneNumber || "-" }}</strong>
            </div>
          </b-col>
          <!-- Kode Referral -->
          <b-col sm="2">
            <h6>Kode Referral</h6>
            <div class="mb-4">
              <strong>{{ this.detailUser.referralCode || "-" }}</strong>
            </div>
          </b-col>
          <!-- Chief -->
          <b-col sm="2">
            <h6>Chief</h6>
            <div class="mb-4">
              <strong>{{ this.detailUser.chief || "-" }}</strong>
            </div>
          </b-col>
          <!-- Total Point -->
          <b-col sm="2">
            <h6>Total Point</h6>
            <div class="mb-4">
              <strong>{{ this.detailUser.totalPoint || "-" }}</strong>
            </div>
          </b-col>
          <!-- Total Benefit -->
          <b-col sm="2">
            <h6>Total Benefit</h6>
            <div class="mb-4">
              <strong>{{ this.detailUser.totalBenefit || "-" }}</strong>
            </div>
          </b-col>
          <!-- Total Treasure -->
          <b-col sm="2">
            <h6>Total Treasure</h6>
            <div class="mb-4">
              <strong>{{ this.detailUser.totalTreasure || "-" }}</strong>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <br />
    <!-- Progress User Army -->
    <b-card no-body v-if="this.progress.promote || this.progress.maintenance">
      <b-card-header>
        Progress User Army
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
          <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
          </div>
        </div>
        <div v-if="this.progress.promote">
          <p class="mb-2 text-center" :style="{ fontSize: '13px' }">
            <strong>Level Up</strong>
          </p>
          <p class="mb-3 text-center" :style="{ fontSize: '13px' }">
            {{ this.progress.promote.duration || "-" }}
          </p>
          <div class="table-responsive-sm">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th :style="{ width: '50%' }">Mission</th>
                  <th :style="{ width: '50%' }">Progress</th>
                </tr>
              </thead>
              <tbody v-if="this.progress.promote.detail.length > 0">
                <tr
                  v-for="(item, index) in progress.promote.detail"
                  :key="index"
                >
                  <td>{{ item.title }}</td>
                  <td>{{ item.progress }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="this.progress.maintenance">
          <p class="mb-2 text-center" :style="{ fontSize: '13px' }">
            <strong>Maintenance</strong>
          </p>
          <p class="mb-3 text-center" :style="{ fontSize: '13px' }">
            {{ this.progress.maintenance.duration || "-" }}
          </p>
          <div class="table-responsive-sm">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th :style="{ width: '50%' }">Mission</th>
                  <th :style="{ width: '50%' }">Progress</th>
                </tr>
              </thead>
              <tbody v-if="this.progress.maintenance.detail.length > 0">
                <tr
                  v-for="(item, index) in progress.maintenance.detail"
                  :key="index"
                >
                  <td>{{ item.title }}</td>
                  <td>{{ item.progress }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td>-</td>
                  <td>-</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <!-- History Title Changes -->
    <b-card no-body>
      <b-card-header>
        History Title Change
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
          <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
          </div>
        </div>
        <div>
          <vuetable
            ref="vuetableHistory"
            track-by="user-slot"
            @vuetable:load-error="handleLoadError"
            :api-url="apiUrlHistory"
            :http-options="HttpOptions"
            :fields="fieldsHistory"
            pagination-path=""
            :muti-sort="true"
            :sort-order="sortOrderHistory"
            :append-params="moreParamsHistory"
            @vuetable:pagination-data="onPaginationData('history', $event)"
          >
            <template slot="title-slot" slot-scope="prop">
              <span>{{ prop.rowData.title }}</span>
            </template>
            <template slot="date-slot" slot-scope="prop">
              <span>{{ prop.rowData.date }}</span>
            </template>
          </vuetable>
          <div class="vuetable-pagination ui basic segment grid">
            <vuetable-pagination-info
              ref="paginationInfoHistory"
            ></vuetable-pagination-info>
            <vuetable-pagination
              ref="paginationHistory"
              @vuetable-pagination:change-page="onChangePage('History', $event)"
            ></vuetable-pagination>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <!-- Troops -->
    <b-card no-body>
      <b-tabs justified>
        <!-- Tab 1 -->
        <b-tab title="Swordsman - Gen 1" active>
          <div>
            <b-row class="mb-3">
              <b-col sm="12">
                <div>
                  <b-form-group
                    class="mb-3"
                    label="Search :"
                    label-for="search-tab1"
                  >
                    <b-input-group>
                      <b-form-input
                        id="search-tab1"
                        type="search"
                        v-model="filterText1"
                        placeholder="Name, Email"
                        @keyup.enter="doFilter('tab1', filterText1)"
                      >
                      </b-form-input>
                      <b-input-group-append>
                        <b-button
                          variant="secondary"
                          type="button"
                          @click="resetFilter('tab1')"
                        >
                          reset
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
            <vuetable
              ref="vuetable1"
              track-by="user-slot"
              @vuetable:load-error="handleLoadError"
              :api-url="apiUrl"
              :http-options="HttpOptions"
              :fields="fields1"
              pagination-path=""
              :muti-sort="true"
              :sort-order="sortOrder1"
              :append-params="moreParams1"
              @vuetable:pagination-data="onPaginationData('tab1', $event)"
            >
              <template slot="date-slot" slot-scope="prop">
                <span>{{ prop.rowData.created_at }}</span>
              </template>
              <template slot="user-slot" slot-scope="prop">
                <span>{{
                  `${prop.rowData.users.name} (${prop.rowData.users.email})`
                }}</span>
              </template>
              <template slot="level-slot" slot-scope="prop">
                <span>{{ prop.rowData.title }}</span>
              </template>
              <template slot="level_up_date-slot" slot-scope="prop">
                <span>{{ prop.rowData.level_up_start_date }}</span>
              </template>
              <template slot="level_up_deadline-slot" slot-scope="prop">
                <span>{{ prop.rowData.level_up_end_date }}</span>
              </template>
              <template slot="maintenance_deadline-slot" slot-scope="prop">
                <span>{{ prop.rowData.maintenance_end_date }}</span>
              </template>
              <template slot="actions-slot" slot-scope="prop">
                <div class="custom-actions">
                  <button
                    class="btn btn-success"
                    v-if="$can('detail_user_army')"
                    @click="onAction('detail-item', prop.rowData)"
                  >
                    Detail
                  </button>
                </div>
              </template>
            </vuetable>
            <div class="vuetable-pagination ui basic segment grid">
              <vuetable-pagination-info
                ref="paginationInfo1"
              ></vuetable-pagination-info>
              <vuetable-pagination
                ref="pagination1"
                @vuetable-pagination:change-page="onChangePage('tab1', $event)"
              ></vuetable-pagination>
            </div>
          </div>
        </b-tab>
        <!-- Tab 2 -->
        <b-tab title="Pikeman - Gen 2">
          <div>
            <b-row class="mb-3">
              <b-col sm="12">
                <div>
                  <b-form-group
                    class="mb-3"
                    label="Search :"
                    label-for="search-tab2"
                  >
                    <b-input-group>
                      <b-form-input
                        id="search-tab2"
                        type="search"
                        v-model="filterText2"
                        placeholder="Name, Email"
                        @keyup.enter="doFilter('tab2', filterText2)"
                      >
                      </b-form-input>
                      <b-input-group-append>
                        <b-button
                          variant="secondary"
                          type="button"
                          @click="resetFilter('tab2')"
                        >
                          reset
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
            <vuetable
              ref="vuetable2"
              track-by="user-slot"
              @vuetable:load-error="handleLoadError"
              :api-url="apiUrl"
              :http-options="HttpOptions"
              :fields="fields2"
              pagination-path=""
              :muti-sort="true"
              :sort-order="sortOrder2"
              :append-params="moreParams2"
              @vuetable:pagination-data="onPaginationData('tab2', $event)"
            >
              <template slot="date-slot" slot-scope="prop">
                <span>{{ prop.rowData.created_at }}</span>
              </template>
              <template slot="user-slot" slot-scope="prop">
                <span>{{
                  `${prop.rowData.users.name} (${prop.rowData.users.email})`
                }}</span>
              </template>
              <template slot="level-slot" slot-scope="prop">
                <span>{{ prop.rowData.title }}</span>
              </template>
              <template slot="level_up_date-slot" slot-scope="prop">
                <span>{{ prop.rowData.level_up_start_date }}</span>
              </template>
              <template slot="level_up_deadline-slot" slot-scope="prop">
                <span>{{ prop.rowData.level_up_end_date }}</span>
              </template>
              <template slot="maintenance_deadline-slot" slot-scope="prop">
                <span>{{ prop.rowData.maintenance_end_date }}</span>
              </template>
              <template slot="actions-slot" slot-scope="prop">
                <div class="custom-actions">
                  <button
                    class="btn btn-success"
                    v-if="$can('detail_user_army')"
                    @click="onAction('detail-item', prop.rowData)"
                  >
                    Detail
                  </button>
                </div>
              </template>
            </vuetable>
            <div class="vuetable-pagination ui basic segment grid">
              <vuetable-pagination-info
                ref="paginationInfo2"
              ></vuetable-pagination-info>
              <vuetable-pagination
                ref="pagination2"
                @vuetable-pagination:change-page="onChangePage('tab2', $event)"
              ></vuetable-pagination>
            </div>
          </div>
        </b-tab>
        <!-- Tab 3 -->
        <b-tab title="Archer - Gen 3">
          <div>
            <b-row class="mb-3">
              <b-col sm="12">
                <div>
                  <b-form-group
                    class="mb-3"
                    label="Search :"
                    label-for="search-tab3"
                  >
                    <b-input-group>
                      <b-form-input
                        id="search-tab3"
                        type="search"
                        v-model="filterText3"
                        placeholder="Name, Email"
                        @keyup.enter="doFilter('tab3', filterText3)"
                      >
                      </b-form-input>
                      <b-input-group-append>
                        <b-button
                          variant="secondary"
                          type="button"
                          @click="resetFilter('tab3')"
                        >
                          reset
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
            <vuetable
              ref="vuetable3"
              track-by="user-slot"
              @vuetable:load-error="handleLoadError"
              :api-url="apiUrl"
              :http-options="HttpOptions"
              :fields="fields3"
              pagination-path=""
              :muti-sort="true"
              :sort-order="sortOrder3"
              :append-params="moreParams3"
              @vuetable:pagination-data="onPaginationData('tab3', $event)"
            >
              <template slot="date-slot" slot-scope="prop">
                <span>{{ prop.rowData.created_at }}</span>
              </template>
              <template slot="user-slot" slot-scope="prop">
                <span>{{
                  `${prop.rowData.users.name} (${prop.rowData.users.email})`
                }}</span>
              </template>
              <template slot="level-slot" slot-scope="prop">
                <span>{{ prop.rowData.title }}</span>
              </template>
              <template slot="level_up_date-slot" slot-scope="prop">
                <span>{{ prop.rowData.level_up_start_date }}</span>
              </template>
              <template slot="level_up_deadline-slot" slot-scope="prop">
                <span>{{ prop.rowData.level_up_end_date }}</span>
              </template>
              <template slot="maintenance_deadline-slot" slot-scope="prop">
                <span>{{ prop.rowData.maintenance_end_date }}</span>
              </template>
              <template slot="actions-slot" slot-scope="prop">
                <div class="custom-actions">
                  <button
                    class="btn btn-success"
                    v-if="$can('detail_user_army')"
                    @click="onAction('detail-item', prop.rowData)"
                  >
                    Detail
                  </button>
                </div>
              </template>
            </vuetable>
            <div class="vuetable-pagination ui basic segment grid">
              <vuetable-pagination-info
                ref="paginationInfo3"
              ></vuetable-pagination-info>
              <vuetable-pagination
                ref="pagination3"
                @vuetable-pagination:change-page="onChangePage('tab3', $event)"
              ></vuetable-pagination>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import Vue from "vue";
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";

Vue.use(Vuetable);

export default {
  name: "detail-user-army",
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo
  },
  data() {
    return {
      detailUser: {
        name: "",
        email: "",
        title: "",
        chief: "",
        phoneNumber: "",
        referralCode: "",
        currentPoint: "",
        totalPoint: "",
        currentBenefit: "",
        totalBenefit: "",
        currentTreasure: "",
        totalTreasure: "",
        registerDate: ""
      },
      progress: {},
      history: [],
      isLoading: false,
      filterText1: "",
      filterText2: "",
      filterText3: "",
      moreParams1: {
        parent_id: atob(this.$route.params.id),
        generation: "swordsman"
      },
      moreParams2: {
        parent_id: atob(this.$route.params.id),
        generation: "pikesman"
      },
      moreParams3: {
        parent_id: atob(this.$route.params.id),
        generation: "archer"
      },
      moreParamsHistory: {
        user_id: atob(this.$route.params.id)
      },
      errors: {
        code: "",
        status: "",
        message: ""
      },
      apiUrl:
        process.env.VUE_APP_SECRET +
        process.env.VUE_APP_CONFIG +
        `army/users/generation`,
      apiUrlHistory:
        process.env.VUE_APP_SECRET +
        process.env.VUE_APP_CONFIG +
        `army/users/history`,
      HttpOptions: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        }
      },
      sortOrder1: [
        {
          field: "created_at",
          sortField: "created_at",
          direction: "desc"
        }
      ],
      sortOrder2: [
        {
          field: "created_at",
          sortField: "created_at",
          direction: "desc"
        }
      ],
      sortOrder3: [
        {
          field: "created_at",
          sortField: "created_at",
          direction: "desc"
        }
      ],
      sortOrderHistory: [
        {
          field: "date",
          sortField: "date",
          direction: "desc"
        }
      ],
      fields1: [
        {
          name: "date-slot",
          sortField: "created_at",
          title: "Register Army Date"
        },
        {
          name: "user-slot",
          sortField: "users.name",
          title: "User"
        },
        {
          name: "level-slot",
          sortField: "title",
          title: "Level"
        },
        {
          name: "level_up_date-slot",
          sortField: "level_up_start_date",
          title: "Level Up Date"
        },
        {
          name: "level_up_program",
          title: "Level Up",
          sortField: "level_up_program",
          formatter: this.formaterProgram
        },
        {
          name: "level_up_deadline-slot",
          title: "Level Up Deadline"
        },
        {
          name: "maintenance_program",
          title: "Maintenance",
          sortField: "maintenance_program",
          formatter: this.formaterProgram
        },
        {
          name: "maintenance_deadline-slot",
          title: "Maintenance Deadline"
        },
        {
          name: "total_amount",
          title: "Transaksi Rp. 500K",
          formatter: this.activeLabel
        },
        {
          name: "kyc_status",
          title: "KYC",
          formatter: this.formaterKYC
        },
        {
          name: "actions-slot",
          title: "Detail",
          titleClass: "center aligned",
          dataClass: "center aligned"
        }
      ],
      fields2: [
        {
          name: "date-slot",
          sortField: "created_at",
          title: "Register Army Date"
        },
        {
          name: "user-slot",
          sortField: "users.name",
          title: "User"
        },
        {
          name: "level-slot",
          sortField: "title",
          title: "Level"
        },
        {
          name: "level_up_date-slot",
          sortField: "level_up_start_date",
          title: "Level Up Date"
        },
        {
          name: "level_up_program",
          title: "Level Up",
          sortField: "level_up_program",
          formatter: this.formaterProgram
        },
        {
          name: "level_up_deadline-slot",
          title: "Level Up Deadline"
        },
        {
          name: "maintenance_program",
          title: "Maintenance",
          sortField: "maintenance_program",
          formatter: this.formaterProgram
        },
        {
          name: "maintenance_deadline-slot",
          title: "Maintenance Deadline"
        },
        {
          name: "total_amount",
          title: "Transaksi Rp. 500K",
          formatter: this.activeLabel
        },
        {
          name: "kyc_status",
          title: "KYC",
          formatter: this.formaterKYC
        },
        {
          name: "actions-slot",
          title: "Detail",
          titleClass: "center aligned",
          dataClass: "center aligned"
        }
      ],
      fields3: [
        {
          name: "date-slot",
          sortField: "created_at",
          title: "Register Army Date"
        },
        {
          name: "user-slot",
          sortField: "users.name",
          title: "User"
        },
        {
          name: "level-slot",
          sortField: "title",
          title: "Level"
        },
        {
          name: "level_up_date-slot",
          sortField: "level_up_start_date",
          title: "Level Up Date"
        },
        {
          name: "level_up_program",
          title: "Level Up",
          sortField: "level_up_program",
          formatter: this.formaterProgram
        },
        {
          name: "level_up_deadline-slot",
          title: "Level Up Deadline"
        },
        {
          name: "maintenance_program",
          title: "Maintenance",
          sortField: "maintenance_program",
          formatter: this.formaterProgram
        },
        {
          name: "maintenance_deadline-slot",
          title: "Maintenance Deadline"
        },
        {
          name: "total_amount",
          title: "Transaksi Rp. 500K",
          formatter: this.activeLabel
        },
        {
          name: "kyc_status",
          title: "KYC",
          formatter: this.formaterKYC
        },
        {
          name: "actions-slot",
          title: "Detail",
          titleClass: "center aligned",
          dataClass: "center aligned"
        }
      ],
      fieldsHistory: [
        {
          name: "date-slot",
          sortField: "date",
          title: "Date"
        },
        {
          name: "title-slot",
          title: "Title"
        }
      ]
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    "$route.params.id"(newId) {
      this.onFetch();
    }
  },
  created() {
    this.onFetch();
  },
  methods: {
    onFetch() {
      this.isLoading = true;
      this.$http
        .get(`army/users/detail?id=${atob(this.$route.params.id)}`)
        .then(result => {
          this.isLoading = false;
          const payload = result.data.data;

          this.detailUser = {
            name: payload.user.users.name,
            email: payload.user.users.email,
            phoneNumber: payload.user.users.phone_number,
            title: payload.user.title,
            chief: payload.user.chief,
            referralCode: payload.user.referral_code,
            currentPoint: payload.user.current_point,
            totalPoint: payload.user.total_point,
            currentBenefit: payload.user.current_benefit,
            totalBenefit: payload.user.total_benefit,
            currentTreasure: payload.user.current_treasure,
            totalTreasure: payload.user.total_treasure,
            registerDate: payload.user.created_at
          };
          this.progress = payload.progress_army;
          this.history = payload.history;
          this.history = payload.history;
        })
        .catch(error => {
          this.isLoading = false;
          if (error.response) {
            this.errors.code = error.response.status;
            this.errors.message = error.response.message;
            this.errors.status = error.response.data.meta.code;
          }
        });
    },
    formaterProgram(value) {
      if (value == 0) {
        return '<font class="text-gray">Tidak Berpartisipasi</font>';
      } else if (value == 1) {
        return "<font>Dalam Proses</font>";
      } else if (value == 2) {
        return '<font class="text-green">Terpenuhi</font>';
      } else if (value == 3) {
        return '<font class="text-red">Gagal</font>';
      } else {
        return "<span> - </span>";
      }
    },
    formaterKYC(value) {
      if (value == "Not Verified") {
        return '<font class="text-gray">Not Verified</font>';
      } else if (value == "Verified") {
        return '<font class="text-green">Verified</font>';
      } else if (value == "Rejected") {
        return '<font class="text-red">Rejected</font>';
      } else if (value == "On Process") {
        return '<font class="text-warning">On Process</font>';
      } else if (value == "Submitted") {
        return "<font>Submitted</font>";
      }
    },
    activeLabel(value) {
      if (value == true) {
        return '<span class="ui green label"><i class="fa fa-check"></i></span>';
      } else {
        return '<span class="ui red label"><i class="fa fa-times"></i></span>';
      }
    },
    currentlyMonth() {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Agu",
        "Sep",
        "Okt",
        "Nov",
        "Des"
      ];

      const date = new Date();
      const month = date.getMonth();
      const year = date.getFullYear();

      const currentMonth = `[${months[month]} ${year}]`;
      return currentMonth;
    },
    onAction(action, data) {
      if (action == "detail-item") {
        // this.$router.replace({ params: { id: data.users.id } });
        const url = this.$router.resolve({
          name: "User Army Detail",
          params: { id: data.users.id }
        }).href;
        window.open(url, "_blank");
      }
    },
    doFilter(type, value) {
      this.$events.$emit("filter-set", type, value);
    },
    onFilterSet(type, filterText) {
      if (type === "tab1") {
        this.moreParams1 = {
          ...Object.fromEntries(
            Object.entries(this.moreParams1).filter(([key]) => {
              return key !== "search";
            })
          ),
          search: encodeURIComponent(filterText)
        };
        Vue.nextTick(() => this.$refs.vuetable1.refresh());
      } else if (type === "tab2") {
        this.moreParams2 = {
          ...Object.fromEntries(
            Object.entries(this.moreParams2).filter(([key]) => {
              return key !== "search";
            })
          ),
          search: encodeURIComponent(filterText)
        };
        Vue.nextTick(() => this.$refs.vuetable2.refresh());
      } else {
        this.moreParams3 = {
          ...Object.fromEntries(
            Object.entries(this.moreParams3).filter(([key]) => {
              return key !== "search";
            })
          ),
          search: encodeURIComponent(filterText)
        };
        Vue.nextTick(() => this.$refs.vuetable3.refresh());
      }
    },
    resetFilter(type) {
      this.$events.$emit("filter-reset", type);
    },
    onFilterReset(type) {
      if (type === "tab1") {
        this.filterText1 = "";
        this.moreParams1 = Object.fromEntries(
          Object.entries(this.moreParams1).filter(([key]) => {
            return key !== "search";
          })
        );
        Vue.nextTick(() => this.$refs.vuetable1.refresh());
      } else if (type === "tab2") {
        this.filterText2 = "";
        this.moreParams2 = Object.fromEntries(
          Object.entries(this.moreParams2).filter(([key]) => {
            return key !== "search";
          })
        );
        Vue.nextTick(() => this.$refs.vuetable2.refresh());
      } else {
        this.filterText3 = "";
        this.moreParams3 = Object.fromEntries(
          Object.entries(this.moreParams3).filter(([key]) => {
            return key !== "search";
          })
        );
        Vue.nextTick(() => this.$refs.vuetable3.refresh());
      }
    },
    handleLoadError(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem("access_token") != null) {
          localStorage.removeItem("access_token");
          this.$swal
            .fire(
              "Your session expired!",
              "Your session has expired. Please login again to access this page!",
              "error"
            )
            .then(() => {
              this.$router.push("/login");
            });
        }
      } else if (this.errors.code == 403) {
        this.$router.push("/403");
      } else if (this.errors.code == 500) {
        this.$router.push("/500");
      }
    },
    onChangePage(type, page) {
      if (type === "tab1") {
        this.$refs.vuetable1.changePage(page);
      } else if (type === "tab2") {
        this.$refs.vuetable2.changePage(page);
      } else if (type === "tab3") {
        this.$refs.vuetable3.changePage(page);
      } else {
        this.$refs.vuetableHistory.changePage(page);
      }
    },
    onPaginationData(type, paginationData) {
      if (type === "tab1") {
        this.$refs.pagination1.setPaginationData(paginationData);
        this.$refs.paginationInfo1.setPaginationData(paginationData);
      } else if (type === "tab2") {
        this.$refs.pagination2.setPaginationData(paginationData);
        this.$refs.paginationInfo2.setPaginationData(paginationData);
      } else if (type === "tab3") {
        this.$refs.pagination3.setPaginationData(paginationData);
        this.$refs.paginationInfo3.setPaginationData(paginationData);
      } else {
        this.$refs.paginationHistory.setPaginationData(paginationData);
        this.$refs.paginationInfoHistory.setPaginationData(paginationData);
      }
    }
  },
  mounted() {
    this.$events.$on("filter-set", (type, eventData) =>
      this.onFilterSet(type, eventData)
    );
    this.$events.$on("filter-reset", eventData =>
      this.onFilterReset(eventData)
    );
  }
};
</script>

<style>
.text-gray {
  color: rgb(128, 128, 128);
}
.text-red {
  color: #cb211f;
}
.text-green {
  color: #35aa4b;
}
.text-warning {
  color: #a78f27;
}

/* Set background and remove border-radius for active tab */
.tabs .nav-tabs .nav-item .nav-link {
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #23282c;
}

.tabs .nav-tabs .nav-item .nav-link.active {
  background-color: #c8ced3;
  border-radius: 0;
  border-bottom: 1px solid #c8ced3;
}

.tab-content {
  border: none;
}
</style>
